import { Cadence } from '../../api-model/enums/cadence';
import { IDateRangeDto } from 'src/app/api-model/date-range-dto';

export class AnalysisSettings {

  public regionId?: string;
  public networkId?: string;
  public cadence: Cadence;
  public dateRange: IDateRangeDto;
  public isCustomDateRange: Boolean;
  
  public readonly smoothingLevelByCadence: { [cadence: number]: number } = {};
  public readonly smoothingPeriods: { [cadence: number]: number[] } = {};

  public constructor() {
    this.smoothingPeriods[Cadence.Daily] = [0, 7, 30];
    this.smoothingPeriods[Cadence.Weekly] = [0, 4, 12];
    this.smoothingPeriods[Cadence.Monthly] = [0, 3, 12];
  }

  public get smoothingLevel() { return this.smoothingLevelByCadence[this.cadence] ?? 0; }

  public get smoothingPeriod() {
    /* INFO:
      - We disabled smoothing option from UI
      - We should also send lookback as zero to backend to get correct data as we send transform as EXPONENTIAL_MOVING_AVERAGE 
      - below is commented thus.
    */
   // return this.smoothingPeriods[this.cadence ?? Cadence.Daily][this.smoothingLevel] ?? 0;
   return 0;
  }

  public setSmoothingLevel(cadence: Cadence, smoothingLevel: number) {
    this.smoothingLevelByCadence[cadence] = smoothingLevel;
  }

}
